import React from "react";
import { Modal, Row, Col } from "antd";
import { warning } from "variables/image";
import "./style.scss";
import BesinsButton from "../../BesinsButton";

type Props = {
  visible?: boolean;
  width?: number;
  title?: string;
  content?: string;
  children?: any;
  cancel?: any;
  cancelText?: string;
  confirm?: any;
  confirmText?: string;
};

function ConfirmationModal(props: Props) {
  const {
    width,
    title,
    children,
    content,
    cancel,
    cancelText,
    confirm,
    confirmText,
  } = props;
  return (
    <Modal
      centered
      visible={props.visible}
      footer={null}
      closable={false}
      width={width}
      className="modal-confirmation"
      wrapClassName="modal-wrapper"
    >
      <div>
        <img src={warning} alt="" />
        <h3>{title}</h3>
        <p>{content}</p>
        {children}
        <hr />
        <Row className="justify-content-center">
          <Col lg={12} style={{ textAlign: "right" }}>
            <BesinsButton onClick={cancel} size="large">
              {cancelText}
            </BesinsButton>
          </Col>
          <Col lg={12} style={{ textAlign: "left" }}>
            <BesinsButton onClick={confirm} type="primary" size="large">
              {confirmText}
            </BesinsButton>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
