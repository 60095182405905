/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
//scss
import "./style.scss";

//images
import { arrow_back } from "../../variables/image";

//libraries
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Tooltip, Avatar, Collapse, Modal } from "antd";
import moment from "moment";

//models
import { RootState } from "../../models/index.model";

//components
import ProgressBarOrder from "../ProgressBar/ProgressBarOrder";

//utils
import { formatMoney } from "../../utils/formatMoney";
import BesinsButton from "../BesinsButton";
import OrderService from "../../services/order.api";
import DeleteOrder from "../BesinsModal/DeleteModal";
import {
  actGetOrderDetail,
  actResetOrderDetail,
} from "../../store/actions/order.action";

const { Panel } = Collapse;

type Props = {
  visible?: boolean;
  onClose?: any;
  getOrderDetail?: any;
  getOrderList?: any;
};

function OrderDetail(props: Props) {
  moment.locale("vi");
  const dispatch = useDispatch();
  //props
  const { onClose, visible, getOrderDetail, getOrderList } = props;
  const order_detail = useSelector(
    (state: RootState) => state.orderReducer.order_detail
  );
  //states
  const [isVisibleModalReason, setIsVisibleModalReason] = useState(false);
  const [value, setValue] = useState("");

  function handleCountPriceSale(amount: any, price: any, percent: any) {
    let pricePerPro = parseInt(amount) * parseFloat(price);
    let discountedPrice = Math.max((pricePerPro * percent) / 100, 0);
    pricePerPro = Math.max(pricePerPro - discountedPrice, 0);
    return pricePerPro;
  }

  function limitTitle(title: string, number: number) {
    if (title?.length < number) {
      return <span style={{ color: "#172B4D" }}>{title}</span>;
    } else {
      return (
        <Tooltip title={title} placement="bottom">
          <span style={{ color: "#172B4D" }}>
            {title?.substring(0, number)}...
          </span>
        </Tooltip>
      );
    }
  }

  function renderHistories(status: any, item: any, key: any) {
    switch (status) {
      case "SUBMIT_ORDER":
        return (
          <div className="mt-3">
            <div className="subdued-caption2 mb-1">
              Đã tạo {moment(item.createdAt).format(`DD MMMM, YYYY - HH:mm`)}
            </div>
            <div className="subdued-caption2">
              <span>Bởi: </span>
              {item.user ? (
                <span style={{ color: "#172B4D" }}>
                  {`${item.user.type}. ${item.user.profile.fullName}`}
                </span>
              ) : (
                <span style={{ color: "#172B4D" }}>--</span>
              )}
            </div>
          </div>
        );
      case "PROCESSING_ORDER":
        return (
          <div className="mt-3">
            <div className="subdued-caption2 mb-1">
              Đã duyệt {moment(item.createdAt).format(`DD MMMM, YYYY - HH:mm`)}
            </div>
            <div className="subdued-caption2">
              <span>Bởi: </span>
              {item.user ? (
                <span style={{ color: "#172B4D" }}>
                  {`${item.user.type}. ${item.user.profile.fullName}`}
                </span>
              ) : (
                <span style={{ color: "#172B4D" }}>--</span>
              )}
            </div>
            <hr style={{ marginBottom: 0 }} />
          </div>
        );
      case "PROCEED_ORDER":
        return (
          <div className="mt-3">
            <div className="subdued-caption2 mb-1">
              Đã xử lý {moment(item.createdAt).format(`DD MMMM, YYYY - HH:mm`)}
            </div>
            <div className="subdued-caption2">
              <span>Bởi: </span>
              {item.user ? (
                <span style={{ color: "#172B4D" }}>
                  {`${item.user.type}. ${item.user.profile.fullName}`}
                </span>
              ) : (
                <span style={{ color: "#172B4D" }}>--</span>
              )}
            </div>
            <hr style={{ marginBottom: 0 }} />
          </div>
        );
      case "BACK_ORDER":
        return (
          <div className="mt-3">
            <div className="subdued-caption2 mb-1">
              Trả đơn {moment(item.createdAt).format(`DD MMMM, YYYY - HH:mm`)}
            </div>
            <div className="subdued-caption2 mb-1">
              <span>Bởi: </span>
              {item.user ? (
                <span style={{ color: "#172B4D" }}>
                  {`${item.user.type}. ${item.user.profile.fullName}`}
                </span>
              ) : (
                <span style={{ color: "#172B4D" }}>--</span>
              )}
            </div>
            <div className="subdued-caption2">
              <span>Lý do: </span>
              <span style={{ color: "#172B4D" }}>
                &ldquo;{item.detail.note}&rdquo;
              </span>
            </div>
            <hr style={{ marginBottom: 0 }} />
          </div>
        );
      case "COMPLETED_ORDER":
        return (
          <div className="mt-3">
            <div className="subdued-caption2 mb-1">
              Hoàn thành{" "}
              {moment(item.createdAt).format(`DD MMMM, YYYY - HH:mm`)}
            </div>
            <div className="subdued-caption2">
              <span>Bởi: </span>
              {item.user ? (
                <span style={{ color: "#172B4D" }}>
                  {`${item.user.type}. ${item.user.profile.fullName}`}
                </span>
              ) : (
                <span style={{ color: "#172B4D" }}>--</span>
              )}
            </div>
            <hr style={{ marginBottom: 0 }} />
          </div>
        );
      case "CANCELED_ORDER":
        return (
          <div className="mt-3">
            <div className="subdued-caption2 mb-1">
              Đã huỷ {moment(item.createdAt).format(`DD MMMM, YYYY - HH:mm`)}
            </div>
            <div className="subdued-caption2 mb-1">
              <span>Bởi: </span>
              {item.user ? (
                <span style={{ color: "#172B4D" }}>
                  {`${item.user.type}. ${item.user.profile.fullName}`}
                </span>
              ) : (
                <span style={{ color: "#172B4D" }}>--</span>
              )}
            </div>
            <div className="subdued-caption2">
              <span>Lý do: </span>
              <span style={{ color: "#172B4D" }}>
                &ldquo;{item.detail.note}&rdquo;
              </span>
            </div>
            <hr style={{ marginBottom: 0 }} />
          </div>
        );
      case "RETURN_ORDER":
        return (
          <div className="mt-3">
            <div className="subdued-caption2 mb-1">
              Trả hàng {moment(item.createdAt).format(`DD MMMM, YYYY - HH:mm`)}
            </div>
            <div className="subdued-caption2 mb-1">
              <span>Bởi: </span>
              {item.user ? (
                <span style={{ color: "#172B4D" }}>
                  {`${item.user.type}. ${item.user.profile.fullName}`}
                </span>
              ) : (
                <span style={{ color: "#172B4D" }}>--</span>
              )}
            </div>
            <div className="subdued-caption2">
              <span>Lý do: </span>
              <span style={{ color: "#172B4D" }}>
                &ldquo;{item.detail.note}
                &rdquo;
              </span>
            </div>
            <hr style={{ marginBottom: 0 }} />
          </div>
        );
      case "RE_ORDER":
        return (
          <div className="mt-3">
            <div className="subdued-caption2 mb-1">
              Đặt lại {moment(item.createdAt).format(`DD MMMM, YYYY - HH:mm`)}
            </div>
            <div className="subdued-caption2">
              <span>Bởi: </span>
              {item.user ? (
                <span style={{ color: "#172B4D" }}>
                  {`${item.user.type}. ${item.user.profile.fullName}`}
                </span>
              ) : (
                <span style={{ color: "#172B4D" }}>--</span>
              )}
            </div>
            <hr style={{ marginBottom: 0 }} />
          </div>
        );
      default:
        return null;
    }
  }

  async function UpdateOrder(id: string) {
    try {
      const res = await OrderService.updateOrder(id);
      if (res) {
        getOrderDetail(id);
      }
    } catch (error) {
      //console.log(error);
    }
  }

  function onHideModalReason() {
    setValue("");
    setIsVisibleModalReason(false);
    dispatch(actResetOrderDetail());
  }

  async function handleCanceledOrder() {
    let variables = {
      listOrder: [order_detail?.data?._id],
      status: "CANCELED",
      note: value,
    };
    let res = await OrderService.rejectOrder(variables);
    if (res.code === 1) {
      popupSuccess({
        title: "Đã huỷ đơn hàng!",
        content: "Đơn hàng đã được huỷ thành công.",
      });
    }
    onHideModalReason();
  }

  function popupSuccess(value: any) {
    Modal.success({
      centered: true,
      title: value.title,
      content: value.content,
      okText: "Đồng ý",
      onOk() {
        getOrderList();
      },
    });
  }

  return (
    <div>
      <DeleteOrder
        title={`Huỷ đơn hàng ${order_detail?.data?.orderId}?`}
        content="Bạn chắc chắn muốn huỷ đơn hàng?"
        textSubmit="Huỷ đơn"
        textCancel="Đóng"
        // textRemove="Xoá"
        value={value}
        toggle={isVisibleModalReason}
        onChange={(e: any) => setValue(e.target.value)}
        onCancel={onHideModalReason}
        onSubmit={handleCanceledOrder}
      />
      <Drawer
        placement={"left"}
        closable={false}
        onClose={onClose}
        visible={visible}
        key={"left"}
        className="orderDetail-drawer"
      >
        <div className="orderDetail-body">
          <div className="header">
            <div className="title">Chi tiết đơn hàng</div>
            <img src={arrow_back} alt="" onClick={onClose} />
          </div>
          <div className="order-detail">
            <div className="order-info">
              <div className="order-info__header">
                <h2>{order_detail?.data?.orderId}</h2>
                {order_detail?.data?.status === "COMPLETED" ||
                order_detail?.data?.status === "DELIVERING" ||
                order_detail?.data?.status === "PROCCED" ? (
                  <BesinsButton
                    type="primary"
                    onClick={() => UpdateOrder(order_detail?.data?._id)}
                    disabled={order_detail?.data?.actDate ? true : false}
                  >
                    Nhận hàng
                  </BesinsButton>
                ) : null}

                {order_detail?.data?.status === "SUBMIT" ? (
                  <BesinsButton
                    type="primary"
                    onClick={() => {
                      setIsVisibleModalReason(true);
                      dispatch(
                        actGetOrderDetail({
                          ...order_detail,
                          isShow: false,
                        })
                      );
                    }}
                    disabled={order_detail?.data?.actDate ? true : false}
                  >
                    Hủy đơn
                  </BesinsButton>
                ) : null}
              </div>
            </div>
            <div className="order-info">
              <h3>Trạng thái đơn hàng</h3>
              <ProgressBarOrder
                order={order_detail?.data}
                status={order_detail?.data?.status}
              />
            </div>
            <div className="order-info">
              <Collapse
                className="order-info-collapse"
                defaultActiveKey={["1"]}
                expandIconPosition="right"
              >
                <Panel header={<h3>Thông tin giao hàng</h3>} key="1">
                  <div className="mb-4">
                    <div className="label">Người tạo đơn</div>
                    <div className="value">
                      {order_detail?.data?.creator?.profile?.fullName}
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="label">Điện thoại</div>
                    <div className="value">{order_detail?.data?.phone}</div>
                  </div>
                  <div className="mb-4">
                    <div className="label">Ghi chú</div>
                    <div className="value">
                      {order_detail?.data?.detail?.note
                        ? order_detail?.data?.detail?.note
                        : "--"}
                    </div>
                  </div>
                  {/* <div className="mb-4">
                    <div className="label">Ngày giao hàng dự kiến</div>
                    <div className="value">
                      {order_detail?.data?.expireAt
                        ? moment(order_detail?.data?.expireAt).lang("vi").format("DD MMMM")
                        : moment().format("DD-MM-YYYY")}
                    </div>
                  </div> */}
                </Panel>
              </Collapse>
            </div>
            <div className="order-info">
              <h3>
                Giỏ hàng{" "}
                <span className="quantity">
                  ({order_detail?.data?.products?.length} sản phẩm)
                </span>
              </h3>
              <div>
                {order_detail?.data?.products.map((item: any, key: any) => {
                  return (
                    <div key={key}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <span className="ink-subtitle2">
                            {item.amount} x &ensp;
                          </span>
                          <span className="ink-body1">
                            {item.product && item.product.name}
                          </span>
                        </div>
                        <div className="ink-body1">
                          <span style={{ textDecoration: "underline" }}>đ</span>{" "}
                          {formatMoney(
                            handleCountPriceSale(
                              item.amount,
                              item.price,
                              item.percent
                            )
                          )}
                        </div>
                      </div>
                      {item.percent > 0 && (
                        <div className="d-flex align-items-center justify-content-end">
                          <span
                            className="ink-caption2"
                            style={{
                              textDecoration: "line-through",
                              color: "#AAB2BF",
                            }}
                          >
                            đ{" "}
                            {formatMoney(
                              parseFloat(item.price) * parseInt(item.amount)
                            )}
                          </span>
                          <span
                            style={{ color: "#F45B19" }}
                            className="ink-caption2"
                          >
                            &ensp;{"-" + item.percent + "%"}
                          </span>
                        </div>
                      )}
                      {key !== order_detail?.data?.products?.length - 1 && (
                        <hr />
                      )}
                    </div>
                  );
                })}
                <div className="total-product">
                  <h4>Tổng tiền:</h4>
                  <span className="mr-1">đ</span>
                  <span>{formatMoney(order_detail?.data?.sum)}</span>
                </div>
                <p className="__text-tax text-right">(Đã bao gồm VAT)</p>
              </div>
            </div>
            <div className="order-info">
              <div className="mb-4">
                <div className="label">Tạo bởi</div>
                {order_detail?.data?.creator?.profile ? (
                  <div className="d-flex align-items-center mt-2">
                    <Avatar
                      size="small"
                      className="mr-2"
                      style={{ background: "#0073c4" }}
                    >
                      {order_detail?.data?.creator?.profile?.fullName[0]}
                    </Avatar>
                    <div className="ink-body1">
                      {limitTitle(
                        order_detail?.data?.creator.profile.fullName,
                        25
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="mt-2">--</div>
                )}
              </div>
              <div className="mb-4">
                <div className="label">Quản lý đơn hàng</div>
                {order_detail?.data?.medRep?.profile ? (
                  <div className="d-flex align-items-center mt-2">
                    <Avatar
                      size="small"
                      className="mr-2"
                      style={{ background: "#0073c4" }}
                    >
                      {order_detail?.data?.medRep?.profile?.fullName[0]}
                    </Avatar>
                    <div className="ink-body1">
                      {limitTitle(
                        order_detail?.data?.medRep.profile.fullName,
                        25
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="mt-2">--</div>
                )}
              </div>
              <div className="mb-4">
                <div className="label">Nhà phân phối</div>
                {order_detail?.data?.distributor?.profile ? (
                  <div className="d-flex align-items-center mt-2">
                    <Avatar
                      size="small"
                      className="mr-2"
                      style={{ background: "#0073c4" }}
                    >
                      {order_detail?.data?.distributor?.profile?.fullName[0]}
                    </Avatar>
                    <div className="ink-body1">
                      {limitTitle(
                        order_detail?.data?.distributor.profile.fullName,
                        25
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="mt-2">--</div>
                )}
              </div>
            </div>
            <div className="order-info" style={{ paddingTop: "0" }}>
              {order_detail?.data?.histories &&
                order_detail?.data?.histories?.map((item: any, key: any) => {
                  return renderHistories(item.type, item, key);
                })}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

OrderDetail.propTypes = {};

export default OrderDetail;
