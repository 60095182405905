/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import OrderCard from "../../components/OrderCard";
import OrderFilter from "../../components/OrderFilter";
import FILTER from "assets/images/filter.svg";
import { Input, Pagination } from "antd";
import { ClipLoader } from "react-spinners";
//scss
import "./style.scss";
import OrderService from "../../services/order.api";
import ORDER_STATUS from "../../variables/orderStatus";
import { Link, useHistory } from "react-router-dom";
import BesinsButton from "../../components/BesinsButton";
import OrderDetail from "../../components/OrderDetail";
import { useDispatch, useSelector } from "react-redux";
import { arrow_back } from "variables/image";

import {
  actGetOrderDetail,
  actResetOrderDetail,
} from "../../store/actions/order.action";
import { RootState } from "../../models/index.model";

const OrderPage = () => {
  let dispatch = useDispatch();
  const history = useHistory();

  //props
  const order_detail = useSelector(
    (state: RootState) => state.orderReducer.order_detail
  );

  //states
  const [isFilter, setIsFilter] = useState(false);
  const [filter, setFilter] = useState({
    statusSelect: ORDER_STATUS,
  });
  const [search, setSearch] = useState();
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  function onLoading() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  async function _getOrder() {
    try {
      const data = { page, title: search, ...filter };
      const res_orders = await OrderService.get(data);
      onLoading();
      setOrders(res_orders.docs);
      setTotal(res_orders.totalDocs);
      setPage(res_orders.page);
    } catch (err) {
      //console.log(err);
    }
  }

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    _getOrder();
  }, [filter, search, page]);

  async function getOrderDetail(orderId: any) {
    try {
      const res = await OrderService.getDetail(orderId);
      dispatch(actGetOrderDetail({ ...order_detail, data: res }));
    } catch (error) {
      //console.log(error);
    }
  }
  function handleClose() {
    history.replace("/");
  }

  useEffect(() => {
    if (order_detail?.isShow) {
      getOrderDetail(order_detail?.orderId);
    }
  }, [order_detail?.isShow]);

  return (
    <>
      <OrderDetail
        visible={order_detail?.isShow}
        onClose={() => dispatch(actResetOrderDetail())}
        getOrderDetail={getOrderDetail}
        getOrderList={_getOrder}
      />

      <OrderFilter
        filter={filter}
        setFilter={(data: any) => setFilter(data)}
        setPage={(page: any) => setPage(page)}
        show={isFilter}
        onClose={() => setIsFilter(false)}
      />
      <div className="order-page">
        <div className="header d-flex">
          <img src={arrow_back} onClick={handleClose} alt="" />
          <h2 className="title">Danh sách đơn hàng</h2>
        </div>
        <div className="order-search">
          <div style={{ width: "calc(100% - 100px)" }}>
            <Input
              size="large"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
              placeholder="Tìm kiếm bằng từ khóa"
              prefix={<SearchOutlined />}
            />
          </div>
          <div className="filter-btn" onClick={() => setIsFilter(true)}>
            <img src={FILTER} alt="filter" /> Lọc
            <span
              className="font-weight-bold"
              style={{ color: "#0073C4", marginLeft: "2px" }}
            >
              {total !== 0 && total !== null ? ` (${total})` : null}
            </span>
          </div>
        </div>
        {!loading && (
          <div className="order-list">
            {orders.length > 0 &&
              orders.map((prop, key) => <OrderCard order={prop} key={key} />)}
          </div>
        )}
        {loading && (
          <div
            className="order-list d-flex align-items-center justify-content-center"
            style={{ height: "400px" }}
          >
            <ClipLoader loading={true} size={30} color={"#0073C4"} />
          </div>
        )}
        <div className="pagination text-right">
          <Pagination
            onChange={(page) => setPage(page)}
            current={page}
            total={total}
          />
        </div>
        <div className="orderpage-btn-order">
          <Link to="/">
            <BesinsButton style={{ width: "100%" }}>Đặt hàng</BesinsButton>
          </Link>
        </div>
      </div>
    </>
  );
};

export default OrderPage;
