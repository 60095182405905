import React from "react";
import moment from "moment";
//components
import { Card } from "antd";
import DropdownMenu from "./Dropdown";
import Tag from "./Tag";
//scss
import "assets/scss/index.scss";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { actGetOrderDetail } from "../../store/actions/order.action";
import { RootState } from "../../models/index.model";

type Props = {
  order: any;
};

const OrderCard = (props: Props) => {
  const dispatch = useDispatch();
  const { order } = props;
  const order_detail = useSelector((state:RootState) => state.orderReducer.order_detail);

  function handleMenuClick() {
    dispatch(actGetOrderDetail({
      ...order_detail,
      isShow: true,
      orderId: order._id
    }))
  }
  return (
    <Card
      className="order-card"
      // title={order.orderId}
      // extra={}
     
    >
      <div className="order-card__header">
        <div className="title" onClick={handleMenuClick}>{order.orderId}</div>
        <DropdownMenu id={order._id} />
      </div>
      <Tag status={order.status} />
      <div className="order-buyer">
        <span className="label">Người tạo đơn: </span>
        {order?.creator?.fullName}
      </div>
      <div className="order-time">
        {moment(order.createdAt).format("DD/MM/YYYY - HH:mm")}
      </div>
    </Card>
  );
};

export default OrderCard;
