import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  calendar_navigation,
  hoithao,
  home_navigation,
  home_navigation_active,
  news_navigation,
  news_navigation_active,
} from "variables/image";
import "./style.scss";

interface IProps {
  children: () => React.ReactNode | JSX.Element;
  location?: any;
}
function WithNavigation(ChildComp: React.ComponentType<any | string>) {
  return (props: IProps) => {
    const pathName = props.location.pathname;
    return (
      <>
        <ChildComp />

        {/* Tabs Navigator */}
        <div className="tab__wrap">
          <Link to="/">
            <div
              className={classNames({
                tab__item: true,
                active: pathName === "/" || "" ? true : false,
              })}
            >
              <img
                src={
                  pathName === "/" || ""
                    ? home_navigation_active
                    : home_navigation
                }
              />
              <span>Trang chủ</span>
            </div>
          </Link>
          <Link to="/news">
            <div
              className={classNames({
                tab__item: true,
                active: pathName === "/news" ? true : false,
              })}
            >
              <img
                src={
                  pathName === "/news"
                    ? news_navigation_active
                    : news_navigation
                }
              />
              <span>Tin tức</span>
            </div>
          </Link>

          <Link to="/events">
            <div
              className={classNames({
                tab__item: true,
                active: pathName === "/events" ? true : false,
              })}
            >
              <img
                src={pathName === "/events" ? hoithao : calendar_navigation}
              />
              <span>Hội thảo</span>
            </div>
          </Link>
          {/* <Link to="/create-order">
            <div
              className={classNames({
                tab__item: true,
                active: pathName === "/test" ? true : false,
              })}
            >
              <img
                src={
                  pathName === "/test"
                    ? contact_navigation_active
                    : contact_navigation
                }
              />
              <span>Liên hệ NPP</span>
            </div>
          </Link> */}
        </div>
      </>
    );
  };
}

export default WithNavigation;
