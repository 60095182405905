import React, { useEffect, useState } from "react";
import Tag from "../../OrderCard/Tag";
import "./style.scss";

type Props = {
  item?: any;
  status?: any;
  isMarked?: boolean;
  time?: string;
  onClick?: any;
  paddingStyle?: any;
  contentStyle?: any;
};
function NotificationItem(props: Props) {
  //props
  const {
    item,
    status,
    time,
    isMarked,
    onClick,
    paddingStyle,
    contentStyle,
  } = props;

  function renderContent() {
    switch (status) {
      //orders
      case "ADMIN_SUBMIT_ORDER":
        return (
          <span>
            Đã cập nhật trạng thái sang <b>đơn mới.</b>
          </span>
        );
      case "ADMIN_PROCESSING_ORDER":
        return <span>Đơn hàng đang được chờ xử lý</span>;
      case "ADMIN_PROCEED_ORDER":
        return (
          <>
            <div>Đơn hàng đã được xử lý</div>
          </>
        );
      case "ADMIN_DELIVERING_ORDER":
        return <span>Đơn hàng bắt đầu đi giao.</span>;
      case "ADMIN_COMPLETE_ORDER":
        return (
          <span>
            Đơn hàng đã được giao <b>thành công.</b>
          </span>
        );
      case "ADMIN_BACK_ORDER":
        return (
          <>
            <div>
              Đơn hàng đang chờ xử lý. Chúng tôi sẽ cập nhật và liên hệ cho Quý
              Bác sĩ
            </div>
          </>
        );
      case "ADMIN_CANCEL_ORDER":
        return (
          <span>
            Đã cập nhật trạng thái sang <b>đã hủy.</b>
          </span>
        );
      case "ADMIN_RETURN_ORDER":
        return (
          <span>
            Đã cập nhật trạng thái sang <b>trả hàng.</b>
          </span>
        );
      case "ADMIN_UPDATE_ORDER":
        return null;
      case "CREATE_ORDER_FOR_ADMIN":
        return <span>Đơn hàng được tạo thành công.</span>;
      case "ADMIN_RE_ORDER":
        return null;
      case "CREATE_ORDER_FOR_BUYER":
        return <span>Đơn hàng vừa được tạo.</span>;
      default:
        return "";
    }
  }

  return (
    <div
      className="content "
      onClick={onClick}
      style={{
        backgroundColor: isMarked ? "#FFFFFF" : "#F5F6F7",
        ...paddingStyle,
      }}
    >
      <div className="content-header">
        <span className="orderId">{item.order.orderId}</span>
        <Tag status={status} />
      </div>
      <p className="content-detail" style={{ ...contentStyle }}>
        {renderContent()}
      </p>
      <p className="time">{time}</p>
    </div>
  );
}

NotificationItem.propTypes = {};

export default NotificationItem;
