import React, { useEffect } from "react";
import "./style.scss";
import OrderService from "../../services/order.api";
import { Switch, Route } from "react-router-dom";
import appRoutes from "../../routes/app.route";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../models/index.model";
import AuthenticationService from "../../services/authentication.api";
import {
  actGetDoctorProfile,
  actGetUserProfile,
} from "../../store/actions/profile.action";
import { socket_cms } from "../../variables/connection";
import { handleSocket } from "../../variables/socket";
import {
  actSetUserAllNotices,
  actSetTotalNotification,
} from "../../store/actions/order.action";
import UserService from "services/user.api";
import Header from "../../components/Header";
import { useMatomo } from "@datapunt/matomo-tracker-react";
// import { useLocation } from "react-router-dom";
type Props = {
  children?: any;
  match?: any;
  token?: string;
};

const AppLayout = (props: Props) => {
  const dispatch = useDispatch();

  const { token: tokenApp } = useSelector(
    (state: RootState) => state.tokenReducer
  );
  const token = localStorage.getItem("access_token");

  const user_info = useSelector(
    (state: RootState) => state.profileReducer.user_info
  );
  async function getAllNotices() {
    let data = {
      limit: 20,
      page: 1,
      type: JSON.stringify(["ORDER"]),
    };
    await OrderService.getNotificationForUser(data).then((res: any) => {
      getTotalNotification();
      if (res?.code === 1) {
        dispatch(actSetUserAllNotices(res.data));
      }
    });
  }

  async function getTotalNotification() {
    try {
      await OrderService.getTotalNotification().then((res: any) => {
        if (res?.code === 1) {
          dispatch(actSetTotalNotification(res.data));
        }
      });
    } catch (error) {
      //console.log(error);
    }
  }

  async function getCurrentUser() {
    try {
      const res = await AuthenticationService.getCurrentUser();
      if (res.code === 1) {
        dispatch(actGetUserProfile(res.data.user));
        localStorage.setItem("fullName", res.data.user.profile.fullName);
        localStorage.setItem("role", res.data.user.type);
      } else {
        //console.log("err user");
      }
    } catch (error) {
      //console.log("error user: ", error);
    }
  }

  const getDoctorInfo = async () => {
    const result = await UserService.getDoctorInfo();
    dispatch(actGetDoctorProfile(result));
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [props.match]);

  useEffect(() => {
    getCurrentUser();
    getAllNotices();
    getDoctorInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenApp]);

  useEffect(() => {
    socket_cms.on("NOTIFICATION", (response: any) => {
      if (response) {
        getAllNotices();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let path = window.location.pathname;
  const { enableLinkTracking } = useMatomo();
  enableLinkTracking();

  useEffect(() => {
    if (user_info._id) {
      if (!(window as any).tidioChatApi) {
        // append tidio script
        const script = document.createElement("script");
        //    account production
        script.src =
          "https://code.tidio.co/xkblosr3k7gxuxm33ktaqsmztrljorvp.js";
        //    account test
        //script.src = "https://code.tidio.co/ajd1ntf816hdfatnd6kq4y9nmbq3kjbh.js";
        script.async = true;
        document.body.appendChild(script);

        // set basic user info
        (document as any).tidioIdentify = {
          distinct_id: user_info._id,
          email: user_info.email,
          name: user_info.profile && user_info.profile.fullName,
          phone: user_info.profile && user_info.profile.phoneOrder,
        };

        // set contact properties
        document.addEventListener("tidioChat-ready", function () {
          (window as any).tidioChatApi.setContactProperties({
            medrep:
              ((user_info as any).medRep &&
                (user_info as any).medRep.profile &&
                (user_info as any).medRep.profile.fullName) ||
              "-----",
            doctor_id:
              (user_info.details && (user_info.details as any).buyerId) ||
              "-----",
            team:
              ((user_info as any).medRep &&
                (user_info as any).medRep.details &&
                (user_info as any).medRep.details.team &&
                (user_info as any).medRep.details.team.name) ||
              "-----",
            province:
              ((user_info as any).channel &&
                (user_info as any).channel.province) ||
              "-----",
          });
        });
      }
      handleSocket(user_info._id);
    }
  }, [user_info]);

  useEffect(() => {
    if (
      user_info?._id &&
      user_info?.details &&
      user_info?.email &&
      user_info?.profile?.fullName &&
      user_info?.profile?.phoneOrder
    ) {
      if (typeof window !== "undefined") {
        var _paq = ((window as any)._paq = (window as any)._paq || []);

        _paq.push([
          "setUserId",
          (user_info.details as any).buyerId +
            " - " +
            user_info.profile.fullName,
        ]);
        // _paq.push(['trackEvent','userInfo',user_info.profile.fullName+" - "+user_info.email +" - "+ user_info.profile.phoneOrder]);
        _paq.push([
          "trackEvent",
          user_info.profile.fullName,
          user_info.email,
          user_info.profile.phoneOrder,
        ]);
        _paq.push(["setCustomUrl", path]);
        _paq.push(["trackPageView"]);
      }
    }
  }, [path, user_info]);

  useEffect(() => {
    //console.log("in layout app");
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      /* iOS hides Safari address bar */
      window.addEventListener("load", function () {
        setTimeout(function () {
          window.scrollTo(0, 1);
        }, 1000);
      });
    }
  }, []);
  function renderHeader() {
    const url = window.location.href.split("/");

    // if (url[3] == "create-order") {
    //   return <Header></Header>;
    // }
    if (url[3] == "news") {
      return <Header></Header>;
    }
  }

  return (
    <div className="app-layout">
      {renderHeader()}
      <div className="main">
        <Switch>
          {appRoutes.map((route, key) => {
            return (
              <Route
                exact={route.exact || false}
                path={route.path}
                component={route.component}
                key={key}
              />
            );
          })}
        </Switch>
      </div>
    </div>
  );
};

export default AppLayout;
