import React from "react";
//components
import { Menu, Button, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
//scss
import "assets/scss/index.scss";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../models/index.model";
import { actGetOrderDetail } from "../../../store/actions/order.action";

const DropdownMenu = (props: any) => {
  let dispatch = useDispatch();
  const order_detail = useSelector((state:RootState) => state.orderReducer.order_detail);

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">
        Xem chi tiết
      </Menu.Item>
    </Menu>
  );

  function handleMenuClick() {
    dispatch(actGetOrderDetail({
      ...order_detail,
      isShow: true,
      orderId: props.id
    }))
  }

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
      <Button icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default DropdownMenu;
