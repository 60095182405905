/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import OrderService from "../../services/order.api";
import { arrow_back } from "../../variables/image";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../models/index.model";
import { timeAgo } from "../../utils/formatDate";
import {
  actSetUserNotification,
  actSetTotalNotification,
  actSetUserAllNotices,
} from "../../store/actions/order.action";
import EventService from "services/event.api";
import { actGetNotificationEvents } from "store/actions/event.action";
import Spinner from "components/Spinner";
import { EventNotification } from "models/event.model";
import classNames from "classnames";
import moment from "moment";
import NotificationItem from "components/Notification/NotificationItem";
import "./style.scss";
import { Tabs } from "antd";
import { useHistory } from "react-router-dom";

type Props = {
  visible?: boolean;
  onToggle?: any;
};

const { TabPane } = Tabs;

function NotificationDrawer(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const all_noti = useSelector(
    (state: RootState) => state.orderReducer.user_all_noti
  );
  const user_info = useSelector(
    (state: RootState) => state.profileReducer.user_info
  );
  const event_noti =
    useSelector(
      (state: RootState) => state.eventReducer.eventsNotification.docs
    ) || [];

  const [loading, setLoading] = useState(false);
  // const [open, setOpen] = useState(false);

  async function getAllNotices() {
    let data = {
      limit: 20,
      page: 1,
      type: JSON.stringify(["ORDER"]),
    };
    await OrderService.getNotificationForUser(data).then((res: any) => {
      getTotalNotification();
      if (res.code === 1) {
        dispatch(actSetUserAllNotices(res.data));
      } else {
        //console.log(res.error);
      }
    });
  }

  async function getTotalNotification() {
    try {
      await OrderService.getTotalNotification().then((res: any) => {
        if (res.code === 1) {
          dispatch(actSetTotalNotification(res.data));
        } else {
          //console.log(res.error);
        }
      });
    } catch (error) {
      //console.log(error);
    }
  }

  async function getNotificationList(data: any) {
    await OrderService.getNotificationForUser(data).then((res) => {
      if (res.code === 1) {
        dispatch(actSetUserNotification(res.data));
      }
    });
  }

  async function getNotificationEvents() {
    setLoading(true);
    try {
      const eventNotification = await EventService.getNotification();
      const { code, data } = eventNotification;
      if (code === 1) {
        dispatch(actGetNotificationEvents(data));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  async function markAsRead(notiId: string, isSeen: boolean) {
    let data = {
      notiId: notiId,
      userId: user_info._id,
    };
    let _params = {
      limit: 10,
      page: 1,
      type: JSON.stringify(["ORDER"]),
    };
    if (!isSeen) {
      try {
        await OrderService.updateNotification(data).then((res: any) => {
          if (res.code === 1) {
            getNotificationList(_params);
            getAllNotices();
          } else {
            //console.log(res.error);
          }
        });
      } catch (error) {
        //console.log(error);
      }
    }
  }

  async function markAsReadAll() {
    let data = {
      generalType: JSON.stringify(["ORDER"]),
    };
    let _params = {
      limit: 10,
      page: 1,
      type: JSON.stringify(["ORDER"]),
    };
    try {
      await OrderService.updateAllNotices(data).then((res) => {
        if (res.code === 1) {
          getNotificationList(_params);
          getTotalNotification();
          getAllNotices();
        } else {
          //console.log(res.error);
        }
      });
    } catch (error) {
      //console.log(error);
    }
  }

  function callback(key: string) {}

  useEffect(() => {
    getNotificationEvents();
  }, []);

  const handleOnClickEvent = async (url: string, id: string) => {
    history.push(`/events/details/${url}?id=${id}`);
  };

  return (
    <div className="notification-body">
      <div className="header">
        <div className="title">Thông báo</div>
        <img src={arrow_back} onClick={() => history.goBack()} alt="" />
      </div>
      <div className="read-mark" onClick={markAsReadAll}>
        Đánh dấu tất cả đã đọc
      </div>
      {all_noti?.docs?.length > 0 &&
        all_noti.docs.map((prop, key) => {
          if (prop.type !== "ADMIN_UPDATE_ORDER") {
            return (
              <NotificationItem
                item={prop}
                status={prop.type}
                time={timeAgo(prop.createdAt, "VN")}
                isMarked={prop.users.isSeen}
                onClick={() => markAsRead(prop._id, prop.users.isSeen)}
              />
            );
          }
          return null;
        })}
      {/* <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Đơn hàng" key="1">
          
        </TabPane>
        <TabPane tab="Hội thảo" key="2">
          <div className="read-mark" onClick={markAsReadAll}>
            Đánh dấu tất cả đã đọc
          </div>
          <div className="event__noti">
            {loading ? (
              <Spinner />
            ) : (
              event_noti.map((eventNoti: EventNotification) => {
                const { event, user, createdAt, detail, type } = eventNoti;
                const url: any = event.url;
                // check date (type)
                let subject = detail.subject;
                if (type=== "REMIND_EVENT_CRONJOB_2_HOUR") {
                  const startDay = moment().format("DD MM YYYY");
                  const endDay = moment(event.timeTakesPlace).format(
                    "DD MM YYYY"
                  );
                  subject =
                    startDay === endDay
                      ? "Sự kiện sẽ diễn ra hôm nay vào lúc " +
                        moment(event.timeTakesPlace).format("HH:mm")
                      : "Sự kiện sẽ diễn ra vào ngày " +
                        moment(event.timeTakesPlace).format("DD-MM-YYYY HH:mm");
                }
               

                return (
                  <div
                    onClick={() => handleOnClickEvent(url, event._id)}
                    className={classNames(
                      "noti__item pb-3",
                      !user?.isSeen && "noti__notRead"
                    )}
                  >
                    <div className="d-flex align-items-center mb-2">
                      <i className="icon icon-notifications mr-2"></i>
                      <div className="noti-calendar">
                        {moment(event.timeTakesPlace).format(
                          "HH:mm, DD [Thg]MM YYYY"
                        )}
                      </div>
                    </div>
                    <div className="noti__body mb-1">{subject}</div>
                    {type === "REMIND_EVENT" && (
                      <div className="noti__body mb-2">{detail.body}</div>
                    )}
                    
                    <div className="mb-2" style={{ fontWeight: 500 }}>
                      {event.title}
                    </div>
                    <div className="d-flex justify-content-between noti__footer">
                      <span
                        className={classNames(
                          "event__tag",
                          event.type === "ONLINE" && "event__tag-online",
                          event.type === "OFFLINE" && "event__tag-offline"
                        )}
                      >
                        {event.type === "OFFLINE"
                          ? "Hội thảo gặp gỡ trực tiếp"
                          : "Hội thảo trực tuyến"}
                      </span>
                      <span className="time">{timeAgo(createdAt, "VN")}</span>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </TabPane>
      </Tabs> */}
    </div>
  );
}

export default NotificationDrawer;
