/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import HeaderHome from "./HeaderHome";
import CreateOrderPage from "views/CreateOrderPage";
import { useSelector } from "react-redux";
import { RootState } from "models/index.model";
import { Avatar, Col, Row } from "antd";
import classNames from "classnames";
import { renderName } from "utils";

import { icon_menu_home, icon_notification_home } from "variables/image";
import { Link } from "react-router-dom";
import HOST_URL_IMAGE from "variables/hostUrl";

type Props = {};

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

const HomePageNew = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const closeInfo = () => {
    setOpen(false);
  };

  const toggleNoti = () => {
    setOpen(false);
  };

  const all_noti = useSelector(
    (state: RootState) => state.orderReducer.user_all_noti
  );

  const user_info = useSelector(
    (state: RootState) => state.profileReducer.doctor_info
  );

  const handleFilterData = () => {
    let filterNoti =
      all_noti &&
      all_noti.docs &&
      all_noti.docs.filter((item) => {
        return item.type !== "ADMIN_UPDATE_ORDER" && !item.users.isSeen;
      });
    return { filterNoti };
  };

  const toggle = () => {
    setOpen(!open);
  };

  const renderHeaderHome = useCallback(() => {
    return (
      <>
        <HeaderHome openInfo={open} closeInfo={closeInfo} />
      </>
    );
  }, [handleFilterData, open]);

  // bug here
  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="home-container">
      <ScrollToTopOnMount />
      <div className="home-content">
        <Row gutter={[0, 0]}>
          <Col span={24} id="info-doctor" style={{ paddingBottom: -48 }}>
            <div
              className={classNames(
                "header d-flex justify-content-between align-items-center",
                scrollPosition >= 20 && "header-anchor"
              )}
            >
              <div className="icon_menu_home">
                <img
                  onClick={toggle}
                  className="icon-menu-home"
                  src={icon_menu_home}
                  alt="icon-menu-home"
                />
              </div>
              <div className="header__noti">
                <div className="notification-settings" onClick={toggleNoti}>
                  <Link to="/notifications">
                    <img
                      className="icon-notification-home"
                      src={icon_notification_home}
                      alt="icon-notification-home"
                    />
                  </Link>
                  {handleFilterData().filterNoti &&
                    handleFilterData().filterNoti.length > 0 && (
                      <div className="notification-numbers">
                        <span>
                          {handleFilterData().filterNoti.length > 99
                            ? "99"
                            : handleFilterData().filterNoti.length}
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div className="header__info">
                {/* <div className="mr-3">
              <span className="subdued-body1">Xin chào,</span>
              <h3>{user_info?.profile?.fullName}</h3>
            </div> */}
                <Link to="/profile">
                  {user_info?.profile?.avatar !== "" && (
                    <Avatar
                      size={48}
                      src={`${HOST_URL_IMAGE}/${user_info?.profile?.avatar}`}
                    />
                  )}
                  {user_info?.profile?.avatar === "" && (
                    <Avatar size={48} style={{ backgroundColor: "#0073C4" }}>
                      {renderName(user_info?.profile?.fullName)}
                    </Avatar>
                  )}
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <CreateOrderPage />

      {renderHeaderHome()}
    </div>
  );
};

export default HomePageNew;
