import React from "react";
import { Tag } from "antd";
//scss
import "../../../assets/scss/index.scss";
import "./style.scss";

type Props = {
  status: string;
  style?: any;
};

const StatusTag = (props: Props) => {
  const { status, style } = props;

  const tagProps = {
    className: "status-tag",
    style,
  };

  switch (status) {
    case "DRAFT":
      return (
        <Tag {...tagProps} color="cyan">
          Nháp
        </Tag>
      );
    case "SUBMIT":
      return (
        <Tag {...tagProps} color="orange">
          Đặt hàng thành công
        </Tag>
      );
    case "PROCESSING":
      return (
        <Tag {...tagProps} color="orange">
          Đã tiếp nhận đơn hàng
        </Tag>
      );
    case "PROCEED":
      return (
        <Tag {...tagProps} color="geekblue">
          Bắt đầu giao hàng
        </Tag>
      );
      case "DELIVERING":
        return (
          <Tag {...tagProps} color="geekblue">
            Đang vận chuyển
          </Tag>
        );
    case "BACK_ORDER":
      return (
        <Tag {...tagProps} color="magenta">
          Đang chờ hàng
        </Tag>
      );
    case "CANCELED":
      return (
        <Tag {...tagProps} color="magenta">
          Đã hủy
        </Tag>
      );
    case "COMPLETED":
      return (
        <Tag {...tagProps} color="green">
          Thành công
        </Tag>
      );
    case "RETURN":
      return (
        <Tag {...tagProps} color="magenta">
          Trả hàng
        </Tag>
      );
    case "CREATE_ORDER_FOR_BUYER":
      return (
        <Tag {...tagProps} color="blue">
          Đặt hàng thành công
        </Tag>
      );
    case "CREATE_ORDER_FOR_ADMIN":
      return (
        <Tag {...tagProps} color="blue">
          Đặt hàng thành công
        </Tag>
      );
    case "ADMIN_CANCEL_ORDER":
      return (
        <Tag {...tagProps} color="volcano">
          Đơn hàng đã hủy
        </Tag>
      );
    case "ADMIN_RETURN_ORDER":
      return (
        <Tag {...tagProps} color="magenta">
          Trả đơn hàng
        </Tag>
      );
    case "ADMIN_PROCEED_ORDER":
      return (
        <Tag {...tagProps} color="magenta">
          Đang lấy hàng
        </Tag>
      );
    case "ADMIN_PROCESSING_ORDER":
      return (
        <Tag {...tagProps} color="warning">
          Đã tiếp nhận
        </Tag>
      );
    case "ADMIN_BACK_ORDER":
      return (
        <Tag {...tagProps} color="processing">
          Đang chờ hàng
        </Tag>
      );
    case "ADMIN_DELIVERING_ORDER":
      return (
        <Tag {...tagProps} color="warning">
          Đang giao hàng
        </Tag>
      );
    case "ADMIN_COMPLETE_ORDER":
      return (
        <Tag {...tagProps} color="success">
          Hoàn thành
        </Tag>
      );
    case "ADMIN_UPDATE_ORDER":
      return null;
    case "ADMIN_RE_ORDER":
      return null;
    default:
      return <Tag {...tagProps} color="gold"></Tag>;
  }
};

export default StatusTag;
