import React from "react";
//scss
import "assets/scss/index.scss";
import "./style.scss";
import { DatePicker, Checkbox } from "antd";
import Button from "../BesinsButton";
import StatusTag from "../OrderCard/Tag";
import ORDER_STATUS from "../../variables/orderStatus";

type Props = {
  onClose: any;
  show: boolean;
  filter: any;
  setFilter: any;
  setPage: any;
};

const OrderFilter = (props: Props) => {
  const { filter, setFilter, setPage, onClose, show } = props;
  const { endDate, startDate, statusSelect } = filter;

  function onCheck(value: string) {
    if (!statusSelect.includes(value)) {
      const filterData = {
        ...filter,
        statusSelect: [...statusSelect, value],
      };
      setFilter(filterData);
    } else {
      const filterData = {
        ...filter,
        statusSelect: statusSelect.filter((prop: string) => prop !== value),
      };
      setFilter(filterData);
    }
    setPage(1);
  }

  function onCheckAll() {
    if (statusSelect.length !== ORDER_STATUS.length) {
      setFilter({ ...filter, statusSelect: ORDER_STATUS });
    } else {
      setFilter({ ...filter, statusSelect: [] });
    }
    setPage(1);
  }

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < startDate.endOf('day');
  }

  return (
    <div className="order-filter-page" hidden={!show}>
      <div className="header">
        <h2>Bộ lọc</h2>
        <div
          className="reset-btn"
          onClick={() => setFilter({ statusSelect: [] })}
        >
          Đặt lại
        </div>
      </div>
      <div className="content">
        <div className="filter-time">
          <div className="form-group" style={{ marginRight: "10px" }}>
            <div className="label">Ngày bắt đầu</div>
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              value={startDate}
              placeholder="Ngày bắt đầu"
              format="DD-MM-YYYY"
              onChange={(value) => setFilter({ ...filter, startDate: value })}
            />
          </div>
          <div className="form-group">
            <div className="label">Ngày kết thúc</div>
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              value={endDate}
              placeholder="Ngày kết thúc"
              format="DD-MM-YYYY"
              disabledDate={disabledDate}
              onChange={(value) => setFilter({ ...filter, endDate: value })}
            />
          </div>
        </div>
        <div className="filter-status">
          <div className="label">Trạng thái</div>
          <div className="selection">
            <div>Tất cả trạng thái</div>
            <Checkbox
              checked={statusSelect.length === ORDER_STATUS.length}
              onChange={() => onCheckAll()}
            />
          </div>
          {ORDER_STATUS.map((prop, key) => (
            <div className="selection" key={key}>
              <StatusTag status={prop} />
              <Checkbox
                checked={statusSelect.includes(prop)}
                onChange={() => onCheck(prop)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="footer">
        <Button onClick={onClose} size="large" style={{ width: "100%" }}>
          Áp dụng
        </Button>
      </div>
    </div>
  );
};

export default OrderFilter;
