import React from "react";
import "./style.scss";
import { Modal, Input, Button } from "antd";

const { TextArea } = Input;
function DeleteOrder(props: any) {
  const {
    value,
    toggle,
    onCancel,
    onSubmit,
    onDelete,
    onChange,
    title,
    content,
    textSubmit,
    textCancel,
    textRemove,
  } = props;
  return (
    <Modal
      title={title}
      visible={toggle}
      centered={true}
      closable={false}
      className="DeleteOrder"
      width={432}
      zIndex={49}
      footer={null}
    >
      <div className="DeleteOrder-body">
        <div className="DeleteOrder-content">
          <div className="DeleteOrder-title">{content}</div>
          <div className="DeleteOrder-text">Lý do</div>
          <div className="DeleteOrder-textarea">
            <TextArea
              rows={4}
              value={value}
              placeholder="Nhập lý do..."
              allowClear
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="DeleteOrder-footer">
        <div className="DeleteOrder-footer__left">
          {textRemove && (
            <Button className="delete" onClick={onDelete}>
              {textRemove}
            </Button>
          )}
        </div>

        <div className="DeleteOrder-footer__right">
          <Button type="default" className="cancel" onClick={onCancel}>
            {textCancel}
          </Button>
          <Button
            type="primary"
            disabled={!value}
            className="deny"
            onClick={onSubmit}
          >
            {textSubmit}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteOrder;
