import React from "react";
import moment from "moment";
import "moment/locale/vi";
//scss
import "./style.scss";
//libraries
import { Progress } from "antd";
import Tag from "../../OrderCard/Tag";

type Props = {
  status: any;
  order?: any;
};

function ProgressBarOrder(props: Props) {
  const { status, order } = props;
  function renderProcessPercent() {
    switch (status) {
      case "SUBMIT":
        return 0;
      case "PROCESSING":
        return 30;
        case "PROCEED":
          return 54;
      case "DELIVERING":
        return 76;
      case "COMPLETED":
        return 100;
      default:
        return -1;
    }
  }

  return (
    <>
      {renderProcessPercent() !== -1 && (
        <>
          <div className="progressBar-order">
            <Progress
              percent={renderProcessPercent()}
              status="active"
              strokeColor="#0073C4"
            />
            {/*percentage after calculated additional height of text  30 / 54 / 76 / 100 */}
            <div
              className={`progress-order order-successed ${
                status === "SUBMIT" ? "active" : ""
              }`}
            >
              Đặt hàng thành công
            </div>
            <div
              className={`progress-order ${
                status === "SUBMIT"
                  ? "in-active"
                  : status === "PROCESSING"
                  ? "active"
                  : ""
              }`}
            >
              Đã tiếp nhận đơn hàng
            </div>
            <div
              className={`progress-order ${
                status === "SUBMIT" || status === "PROCESSING"
                  ? "in-active"
                  : status === "PROCEED"
                  ? "active"
                  : ""
              }`}
            >
              Xuất hóa đơn
            </div>
            <div
              className={`progress-order ${
                status === "COMPLETED"
                  ? ""
                  : status === "DELIVERING"
                  ? "active"
                  : "in-active"
              }`}
            >
              Bắt đầu giao hàng
            </div>
            <div
              className={`progress-order ${
                status === "COMPLETED" ? "active" : "in-active"
              }`}
            >
              Hoàn Thành
            </div>
            {(status === "PROCEED" || status === "DELIVERING") && (
              order.expiredAt ? (
                <div className="deliver-date">
                  <p className="text-right" style={{ marginBottom: 4 }}>
                    Dự kiến
                  </p>
                  <p className="datetime">
                    {moment(order.expiredAt).locale("vi").format("DD MMMM, YYYY")}
                  </p>
                </div>
              ) : (
                <div className="deliver-date">
                  <p className="text-right" style={{ marginBottom: 4 }}>
                    Dự kiến
                  </p>
                  <p className="datetime">
                    --
                  </p>
                </div>
              )
            )}
          </div>
        </>
      )}
      {renderProcessPercent() === -1 && <Tag status={status} />}
    </>
  );
}

ProgressBarOrder.propTypes = {};

export default ProgressBarOrder;
