import React, { useState } from "react";
import { Avatar, Drawer, Dropdown } from "antd";
import { vi_lang, icon_event, arrow_back, promotion2 } from "variables/image";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "models/index.model";
import HOST_URL_IMAGE from "variables/hostUrl";
import CalendarComponent from "./Calendar/Calendar";
import BesinsButton from "components/BesinsButton";
import { EventItemType, EventsByDate } from "models/event.model";
import moment from "moment";
import classNames from "classnames";
import "./style.scss";
import { actStatePageNews } from "store/actions/news.action";
import { renderName } from "utils";

type Props = {
  closeInfo?: any;
  openInfo?: boolean;
};

const Header = (props: Props) => {
  const { closeInfo, openInfo } = props;
  const history = useHistory();
  const [openEvent, setOpenEvent] = useState(false);
  const [events, setEvents] = useState([]);
  //
  const dispatch = useDispatch();
  const user_info = useSelector(
    (state: RootState) => state.profileReducer.doctor_info
  );

  const openUserInfo = () => {
    history.push("/profile");
  };
  const openSavesNew = () => {
    history.push("/news/saved");
    dispatch(actStatePageNews("default"));
  };
  function renderType() {
    switch (user_info?.type) {
      case "ADMIN":
        return "Quản trị viên";
      case "MR":
        return "Trình dược viên";
      case "DISTR":
        return "Nhà phân phối";
      case "DOCTOR":
        return "Bác sĩ";
      case "CUSTOMER":
        return "Khách hàng";
      default:
        return "Thành viên";
    }
  }

  function renderEventsByDate() {
    if (events.length > 0) {
      return events.map((event: EventItemType) => {
        const { title, type, timeTakesPlace, _id, url } = event!;
        return (
          <div
            key={`event-${_id}`}
            onClick={() => history.push(`/events/details/${url}?id=${_id}`)}
          >
            <div
              className={classNames({
                "event-by-date": true,
                "event-primary": type === "OFFLINE",
                "event-success": type === "ONLINE",
              })}
            >
              <div className="event-date mb-2">
                {moment(timeTakesPlace).format("DD-MM-YYYY")}
              </div>
              <div className="event__text">{title}</div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="event-null text-center">
          <div className="event-null-text">Không có lịch hội thảo</div>
          <BesinsButton type="link" onClick={() => history.push("/events")}>
            Đăng ký ngay
          </BesinsButton>
        </div>
      );
    }
  }

  return (
    <header>
      {/* <NotificationDrawer visible={openNoti} onClose={closeNoti} /> */}

      <Drawer
        className="menu-sider"
        placement={"left"}
        closable={false}
        onClose={closeInfo}
        visible={openInfo}
        key={"left"}
      >
        <div className="header">
          <div className="title">Thông tin tài khoản</div>
          <img src={arrow_back} onClick={closeInfo} alt="" />
        </div>

        <div className="menu-settings-container">
          <div className="menu-settings">
            <div
              className="info-settings mb-1 d-flex align-items-center"
              onClick={openUserInfo}
            >
              <div
                className="ant-dropdown-link"
                style={{ display: "flex", alignItems: "center" }}
              >
                {user_info?.profile?.avatar !== "" && (
                  <Avatar
                    src={`${HOST_URL_IMAGE}/${user_info?.profile?.avatar}`}
                  ></Avatar>
                )}
                {user_info?.profile?.avatar === "" && (
                  <Avatar style={{ backgroundColor: "#0073C4" }}>
                    {renderName(user_info?.profile?.fullName)}
                  </Avatar>
                )}

                <div className="dropdown-title">
                  {user_info?.profile?.fullName}
                  <div className="dropdown-position">{renderType()}</div>
                </div>
              </div>
              <i className="icon icon-chevron-right"></i>
            </div>
            {/* <div className="item-li" onClick={() => setOpenEvent(true)}>
              <div className="d-flex align-items-center">
                <img src={icon_event} alt="icon_event" />
                <span className="text">Hội thảo của tôi</span>
              </div>
              <i className="icon icon-chevron-right"></i>
            </div> */}

            {/* <div className="item-li" onClick={openSavesNew}>
              <div className="d-flex align-items-center">
                <img src={promotion2} alt="icon_promotion" />
                <span className="text">Tin đã lưu của tôi</span>
              </div>
              <i className="icon icon-chevron-right"></i>
            </div> */}

            <div className="item-li" onClick={() => history.push("/orders")}>
              <div className="d-flex align-items-center">
                <i className="icon icon-order-list"></i>
                <span className="text">Tích lũy của tôi</span>
              </div>
              <i className="icon icon-chevron-right"></i>
            </div>
            {user_info?.details?.isOrderable === true && (
              <div
                className="item-li"
                onClick={() => history.push("/promotions")}
              >
                <div className="d-flex align-items-center">
                  <img src={promotion2} alt="icon_promotion" />
                  <span className="text">
                    Chương trình ưu đãi đặc biệt từ NPP
                  </span>
                </div>
                <i className="icon icon-chevron-right"></i>
              </div>
            )}
            <div className="item-li" onClick={() => history.push("/settings")}>
              <div className="d-flex align-items-center">
                <i className="icon icon-setting"></i>
                <span className="text">Cài đặt</span>
              </div>
              <i className="icon icon-chevron-right"></i>
            </div>

            <div className="language-settings">
              <div className="icon">
                <img src={vi_lang} alt="" />
                <span className="text">Tiếng Việt</span>
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      {/* Event */}
      {/* <Drawer
        className="menu-sider"
        placement={"left"}
        closable={false}
        onClose={() => setOpenEvent(false)}
        visible={openEvent}
      >
        <div className="header">
          <div className="title">Hội thảo của tôi</div>
          <img
            src={arrow_back}
            onClick={() => setOpenEvent(false)}
            alt="arrow-back"
          />
        </div>
        <div className="event__content">
          <div className="event__date">
            <h4 className="title mb-3">Lịch hội thảo đã đăng ký</h4>
            <CalendarComponent
              listEventCallBack={(listEvents: EventItemType[] | EventsByDate) =>
                setEvents((listEvents as any) || [])
              }
            />
            {renderEventsByDate()}
          </div>
        </div>
      </Drawer> */}
    </header>
  );
};

export default Header;
