import React, { useState } from "react";
import "./style.scss";
import { Avatar, Menu, Drawer, Dropdown } from "antd";
import { icon_filter, search } from "../../variables/image";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../models/index.model";
import { actGetSetOrder } from "../../store/actions/order.action";
import NotificationDrawer from "../Notification";
import BesinsButton from "../BesinsButton";
import { actGetSetNews } from "store/actions/news.action";
import ShowSearchModal from "../BesinsModal/showSearchNewModal";

const { SubMenu } = Menu;

// const { Sider } = Layout;
type Props = {};

type Route = {
  name: string;
  icon: string;
  path: string;
  collapse: boolean;
  invisible?: boolean;
  views?: any;
  permission?: string;
};

const Header = (props: Props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  let pathname = window.location.pathname;

  const user_info = useSelector(
    (state: RootState) => state.profileReducer.user_info
  );

  const order = useSelector((state: RootState) => state?.orderReducer?.order);
  const news = useSelector((state: RootState) => state?.newsReducer?.news);

  const all_noti = useSelector(
    (state: RootState) => state.orderReducer.user_all_noti
  );

  function handleFilterData() {
    let filterNoti =
      all_noti &&
      all_noti.docs &&
      all_noti.docs.filter((item) => {
        return item.type !== "ADMIN_UPDATE_ORDER" && !item.users.isSeen;
      });
    return { filterNoti };
  }

  const [open, setOpen] = useState(false);
  const [openNoti, setOpenNoti] = useState(false);
  const [modelSearch, setModelSearch] = useState(false);

  let defaultColor = "#f5f6f7";
  let toggleColor = "#ffffff";

  function toggleNoti() {
    setOpen(false);
    setOpenNoti(true);
  }

  function renderType() {
    switch (user_info.type) {
      case "ADMIN":
        return "Quản trị viên";
      case "MR":
        return "Trình dược viên";
      case "DISTR":
        return "Nhà phân phối";
      case "DOCTOR":
        return "Bác sĩ";
      case "CUSTOMER":
        return "Khách hàng";
      default:
        return "Thành viên";
    }
  }

  function goToInfo() {
    dispatch(
      actGetSetOrder({
        ...order,
        step: 0,
      })
    );
  }
  function goToProducts() {
    dispatch(
      actGetSetOrder({
        ...order,
        step: 1,
      })
    );
  }

  function onToggleNewsFilter() {
    dispatch(
      actGetSetNews({
        ...news,
        isShow: !news.isShow,
      })
    );
  }

  // function onToggoSearch() {
  //   dispatch(
  //     actGetSetNews({
  //       ...news,
  //       isShow: !news.isShow,
  //     })
  //   );
  // }

  return (
    <header className="">
      {/* <NotificationDrawer visible={openNoti} onClose={closeNoti} /> */}
      <div
        className="sider-header "
        style={{ backgroundColor: !open ? defaultColor : toggleColor }}
      >
        <div className="menu-toggler">
          {pathname === "/news" && (
            <div className="flex-between menu-news">
              <h3>Danh sách tin tức</h3>
              <div className="menu-views__filter" aria-hidden="true">
                <img
                  style={{ marginRight: 25 }}
                  src={search}
                  alt="search"
                  onClick={() => {
                    setModelSearch(true);
                  }}
                />
                <img
                  src={icon_filter}
                  alt="filter"
                  onClick={onToggleNewsFilter}
                />
                <span className="subtitle-2" onClick={onToggleNewsFilter}>
                  Lọc
                </span>
              </div>
            </div>
          )}

          {pathname !== "/news" && (
            <>
              {(!order.step || order.step === 0) && (
                <Link to="/">
                  <div style={{ color: "#6B778C" }}>
                    <i className="icon icon-arrow-back" />
                    <span></span>
                  </div>
                </Link>
              )}
              {order.step === 1 && (
                <div className="sidenav-toggler-inner">
                  <div className="nav-step">
                    <i className="icon icon-arrow-back" onClick={goToInfo} />
                    <div className="h3-text">Chọn sản phẩm</div>
                  </div>
                </div>
              )}
              {order.step === 2 && (
                <div className="sidenav-toggler-inner">
                  <div className="nav-step">
                    <i
                      className="icon icon-arrow-back"
                      onClick={goToProducts}
                    />
                    <div className="h3-text">Xác nhận đơn hàng</div>
                  </div>
                </div>
              )}

              {pathname === "/promotions" && (
                <Link to="/">
                  <BesinsButton type="primary" style={{ marginRight: 12 }}>
                    Đặt hàng
                  </BesinsButton>
                </Link>
              )}

              <div
                className={"bell-notification"}
                onClick={() => history.push("/notifications")}
              >
                <div
                  className="icon icon-notification"
                  onClick={toggleNoti}
                ></div>
                {handleFilterData().filterNoti &&
                  handleFilterData().filterNoti.length > 0 && (
                    <div className="notification-numbers">
                      <span>
                        {handleFilterData().filterNoti.length > 99
                          ? "99"
                          : handleFilterData().filterNoti.length}
                      </span>
                    </div>
                  )}
              </div>
            </>
          )}
        </div>
      </div>
      <ShowSearchModal
        allowClear={true}
        visible={modelSearch}
        onCancel={() => setModelSearch(false)}
        closable={false}
      />
      {/* <Drawer
        className="menu-sider"
        placement={"left"}
        closable={false}
        onClose={toggle}
        visible={open}
        key={"left"}
      >
        <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
          {appRoutes.map((prop: any) => {
            if (prop.invisible) {
              return null;
            }
            if (prop.collapse) {
              return (
                <SubMenu
                  style={{ color: "#6b778c" }}
                  key={prop.path}
                  title={
                    <div className="menu-item">
                      <i className={prop.icon} />
                      <span>{prop.name}</span>
                    </div>
                  }
                >
                  {prop.views.map((prop2: Route) => {
                    return (
                      <Menu.Item key={prop2.path}>
                        <Link to={prop2.path} onClick={closeMenu}>
                          <div>{prop2.name}</div>
                        </Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={prop.path} title={prop.name}>
                  <Link to={prop.path} onClick={closeMenu}>
                    <div className="menu-item">
                      <i className={prop.icon} />
                      <span>{prop.name}</span>
                    </div>
                  </Link>
                </Menu.Item>
              );
            }
          })}
        </Menu>

        <div className="menu-settings-container">
          <div className="menu-settings">
            <Dropdown
              overlayClassName="custom-dropdown-sidebar"
              placement="topLeft"
              overlay={
                <Menu>
                  <Menu.Item
                    key="2"
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={logout}
                  >
                    <i className="icon icon-logout" />
                    <span>Đăng xuất</span>
                  </Menu.Item>
                </Menu>
              }
            >
              <div className="info-settings">
                <div
                  className="ant-dropdown-link"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {user_info?.profile?.avatar !== "" && (
                    <Avatar
                      src={`${HOST_URL_IMAGE}/${user_info?.profile?.avatar}`}
                    ></Avatar>
                  )}
                  {user_info?.profile?.avatar === "" && (
                    <Avatar style={{ backgroundColor: "#0073C4" }}>
                      {user_info?.profile?.fullName![0]}
                    </Avatar>
                  )}

                  <div className="dropdown-title">
                    {user_info?.profile?.fullName}
                    <div className="dropdown-position">{renderType()}</div>
                  </div>
                </div>
              </div>
            </Dropdown>
            <div className="notification-settings" onClick={toggleNoti}>
              <div className="icon icon-notification" onClick={toggleNoti}>
                <span className="text">Thông Báo</span>
              </div>
              {handleFilterData().filterNoti &&
                handleFilterData().filterNoti.length > 0 && (
                  <div className="notification-numbers">
                    <span>
                      {handleFilterData().filterNoti.length > 99
                        ? "99"
                        : handleFilterData().filterNoti.length}
                    </span>
                  </div>
                )}
            </div>
            <div className="event">
              <img src={icon_event} alt="icon_event" />
              <span className="text">Sự kiện của tôi</span>
            </div>
            <div className="language-settings">
              <div className="icon">
                <img src={vi_lang} alt="" />
                <span className="text">Tiếng Việt</span>
              </div>
            </div>
          </div>
        </div>
      </Drawer> */}
    </header>
  );
};

export default Header;
