const ORDER_STATUS = [
  "SUBMIT",
  "PROCESSING",
  "PROCEED",
  "DELIVERING",
  "COMPLETED",
  "BACK_ORDER",
  "RETURN",
  "CANCELED",
];

export default ORDER_STATUS;